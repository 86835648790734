import revive_payload_client_4sVQNw7RlN from "/var/www/app-front/apps/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/var/www/app-front/apps/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/app-front/apps/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/app-front/apps/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/app-front/apps/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/var/www/app-front/apps/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/var/www/app-front/apps/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/var/www/app-front/apps/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/app-front/apps/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import pinia_48xmdi2HHl from "/var/www/app-front/apps/plugins/pinia.ts";
import vuetree_select_56XYAXXOdy from "/var/www/app-front/apps/plugins/vuetree-select.ts";
import internet_connection_qmNk5ogxp5 from "/var/www/app-front/apps/plugins/internet-connection.ts";
import Datepicker_B97gNW2fHu from "/var/www/app-front/apps/plugins/Datepicker.ts";
import VuePhoneNumberInput_tmJCC0G3BD from "/var/www/app-front/apps/plugins/VuePhoneNumberInput.ts";
import axios_QMFgzss1s4 from "/var/www/app-front/apps/plugins/axios.ts";
import clickOutside_ZB3Eg0wOVk from "/var/www/app-front/apps/plugins/clickOutside.ts";
import firebaseAuth_client_OP6eaV11Gv from "/var/www/app-front/apps/plugins/firebaseAuth.client.ts";
import perfectScrollbar_WVCNASQq6w from "/var/www/app-front/apps/plugins/perfectScrollbar.ts";
import vueToastification_TGgIjy9mOG from "/var/www/app-front/apps/plugins/vueToastification.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  pinia_48xmdi2HHl,
  vuetree_select_56XYAXXOdy,
  internet_connection_qmNk5ogxp5,
  Datepicker_B97gNW2fHu,
  VuePhoneNumberInput_tmJCC0G3BD,
  axios_QMFgzss1s4,
  clickOutside_ZB3Eg0wOVk,
  firebaseAuth_client_OP6eaV11Gv,
  perfectScrollbar_WVCNASQq6w,
  vueToastification_TGgIjy9mOG
]