import { default as already_signed_documentAUMekKzbeDMeta } from "/var/www/app-front/apps/pages/already_signed_document.vue?macro=true";
import { default as change_passworduvrOIAw7psMeta } from "/var/www/app-front/apps/pages/auth/change_password.vue?macro=true";
import { default as confirmFcJYqqMmRxMeta } from "/var/www/app-front/apps/pages/auth/confirm.vue?macro=true";
import { default as forgot_passwordR61WscPEUEMeta } from "/var/www/app-front/apps/pages/auth/forgot_password.vue?macro=true";
import { default as reset_passwordnU2aMAlGaZMeta } from "/var/www/app-front/apps/pages/auth/reset_password.vue?macro=true";
import { default as sign_fast_changeFj22Cif459Meta } from "/var/www/app-front/apps/pages/auth/sign_fast_change.vue?macro=true";
import { default as sign_innP6zoaZGLrMeta } from "/var/www/app-front/apps/pages/auth/sign_in.vue?macro=true";
import { default as sign_upTOz1ZxpDKIMeta } from "/var/www/app-front/apps/pages/auth/sign_up.vue?macro=true";
import { default as email_editor8eAdQExB9lMeta } from "/var/www/app-front/apps/pages/email_editor.vue?macro=true";
import { default as checkingX9umjB4MA3Meta } from "/var/www/app-front/apps/pages/file/[id]/checking.vue?macro=true";
import { default as indexwdRDaTly8IMeta } from "/var/www/app-front/apps/pages/file/[id]/index.vue?macro=true";
import { default as messageF6Ujd4UcJaMeta } from "/var/www/app-front/apps/pages/file/[id]/message.vue?macro=true";
import { default as previewOf9qYbaxzPMeta } from "/var/www/app-front/apps/pages/file/[id]/preview.vue?macro=true";
import { default as shareMmGZ63Cl9iMeta } from "/var/www/app-front/apps/pages/file/[id]/share.vue?macro=true";
import { default as signedolfizDCtI1Meta } from "/var/www/app-front/apps/pages/file/[id]/signed.vue?macro=true";
import { default as template_previewA0tn30hkzcMeta } from "/var/www/app-front/apps/pages/file/[id]/template_preview.vue?macro=true";
import { default as indexOxED3j6C0wMeta } from "/var/www/app-front/apps/pages/index.vue?macro=true";
import { default as v1_integrateh19X33iN2VMeta } from "/var/www/app-front/apps/pages/integration/v1_integrate.vue?macro=true";
import { default as not_allowed9xjbxxm2jWMeta } from "/var/www/app-front/apps/pages/not_allowed.vue?macro=true";
import { default as fast_authTrs2iQAXCSMeta } from "/var/www/app-front/apps/pages/signer/[id]/fast_auth.vue?macro=true";
import { default as indexgjTmm2wOYWMeta } from "/var/www/app-front/apps/pages/signer/[id]/index.vue?macro=true";
import { default as preview9EUfyrv7skMeta } from "/var/www/app-front/apps/pages/signer/[id]/preview.vue?macro=true";
import { default as sentToJua5bw3yMeta } from "/var/www/app-front/apps/pages/signer/[id]/sent.vue?macro=true";
import { default as signingUAd69ezHUNMeta } from "/var/www/app-front/apps/pages/signer/[id]/signing.vue?macro=true";
import { default as summarygnz5AFJXTeMeta } from "/var/www/app-front/apps/pages/signer/[id]/summary.vue?macro=true";
import { default as validationL6fZin631YMeta } from "/var/www/app-front/apps/pages/signer/[id]/validation.vue?macro=true";
import { default as verificationTiC41YAVEDMeta } from "/var/www/app-front/apps/pages/signer/[id]/verification.vue?macro=true";
import { default as create_accountWVr1YZ3vtPMeta } from "/var/www/app-front/apps/pages/signer/create_account.vue?macro=true";
import { default as create_passwordkiDLPlSAUkMeta } from "/var/www/app-front/apps/pages/signer/create_password.vue?macro=true";
import { default as indexauTgDaHIzCMeta } from "/var/www/app-front/apps/pages/signy/allDocuments/index.vue?macro=true";
import { default as indexsCF7QsBgLiMeta } from "/var/www/app-front/apps/pages/signy/auth/index.vue?macro=true";
import { default as successN8GbkFJt8dMeta } from "/var/www/app-front/apps/pages/signy/auth/success.vue?macro=true";
import { default as contactsr0Ap9V5xJWMeta } from "/var/www/app-front/apps/pages/signy/contacts.vue?macro=true";
import { default as dashboardexPPnQ6Cj4Meta } from "/var/www/app-front/apps/pages/signy/dashboard.vue?macro=true";
import { default as erroryZN0bX7zpYMeta } from "/var/www/app-front/apps/pages/signy/error.vue?macro=true";
import { default as all_documentsnGm4O8PUKjMeta } from "/var/www/app-front/apps/pages/signy/explorer/all_documents.vue?macro=true";
import { default as indexUeVipOXSemMeta } from "/var/www/app-front/apps/pages/signy/explorer/contacts/index.vue?macro=true";
import { default as formsprr1bSDPDWMeta } from "/var/www/app-front/apps/pages/signy/explorer/forms.vue?macro=true";
import { default as indexlWMBo13UkFMeta } from "/var/www/app-front/apps/pages/signy/explorer/groups/index.vue?macro=true";
import { default as index4DiuJzLswdMeta } from "/var/www/app-front/apps/pages/signy/explorer/index.vue?macro=true";
import { default as old_45contactszj4o0rIe05Meta } from "/var/www/app-front/apps/pages/signy/explorer/old-contacts.vue?macro=true";
import { default as templatestZWmLpjUybMeta } from "/var/www/app-front/apps/pages/signy/explorer/templates.vue?macro=true";
import { default as trashGd2V0m6mlCMeta } from "/var/www/app-front/apps/pages/signy/explorer/trash.vue?macro=true";
import { default as indexzAZ7zRk1sPMeta } from "/var/www/app-front/apps/pages/signy/index.vue?macro=true";
import { default as documentspGfmQtJLx0Meta } from "/var/www/app-front/apps/pages/signy/my_account/documents.vue?macro=true";
import { default as filesGA6EZQQCSyMeta } from "/var/www/app-front/apps/pages/signy/my_account/files.vue?macro=true";
import { default as indexefQWCDOTkdMeta } from "/var/www/app-front/apps/pages/signy/my_account/index.vue?macro=true";
import { default as organizationsbdLgkJ864KMeta } from "/var/www/app-front/apps/pages/signy/my_account/organizations.vue?macro=true";
import { default as profileETK9f8iXgmMeta } from "/var/www/app-front/apps/pages/signy/my_account/profile.vue?macro=true";
import { default as indexurT2e6FZhwMeta } from "/var/www/app-front/apps/pages/signy/my_account/settings/index.vue?macro=true";
import { default as my_account0zRmcyxw3LMeta } from "/var/www/app-front/apps/pages/signy/my_account.vue?macro=true";
import { default as pdf_creatorJ2YWyBhtFoMeta } from "/var/www/app-front/apps/pages/signy/pdf_creator.vue?macro=true";
import { default as pdf_viewerHaDdyXLPapMeta } from "/var/www/app-front/apps/pages/signy/pdf_viewer.vue?macro=true";
import { default as plansBUNS91VT0NMeta } from "/var/www/app-front/apps/pages/signy/plans.vue?macro=true";
import { default as upload_documentrDzbVvftgiMeta } from "/var/www/app-front/apps/pages/signy/upload_document.vue?macro=true";
import { default as v1_integrationGDtFMpTYNyMeta } from "/var/www/app-front/apps/pages/v1_integration.vue?macro=true";
export default [
  {
    name: already_signed_documentAUMekKzbeDMeta?.name ?? "already_signed_document",
    path: already_signed_documentAUMekKzbeDMeta?.path ?? "/already_signed_document",
    meta: already_signed_documentAUMekKzbeDMeta || {},
    alias: already_signed_documentAUMekKzbeDMeta?.alias || [],
    redirect: already_signed_documentAUMekKzbeDMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/already_signed_document.vue").then(m => m.default || m)
  },
  {
    name: change_passworduvrOIAw7psMeta?.name ?? "auth-change_password",
    path: change_passworduvrOIAw7psMeta?.path ?? "/auth/change_password",
    meta: change_passworduvrOIAw7psMeta || {},
    alias: change_passworduvrOIAw7psMeta?.alias || [],
    redirect: change_passworduvrOIAw7psMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/change_password.vue").then(m => m.default || m)
  },
  {
    name: confirmFcJYqqMmRxMeta?.name ?? "auth-confirm",
    path: confirmFcJYqqMmRxMeta?.path ?? "/auth/confirm",
    meta: confirmFcJYqqMmRxMeta || {},
    alias: confirmFcJYqqMmRxMeta?.alias || [],
    redirect: confirmFcJYqqMmRxMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: forgot_passwordR61WscPEUEMeta?.name ?? "auth-forgot_password",
    path: forgot_passwordR61WscPEUEMeta?.path ?? "/auth/forgot_password",
    meta: forgot_passwordR61WscPEUEMeta || {},
    alias: forgot_passwordR61WscPEUEMeta?.alias || [],
    redirect: forgot_passwordR61WscPEUEMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: reset_passwordnU2aMAlGaZMeta?.name ?? "auth-reset_password",
    path: reset_passwordnU2aMAlGaZMeta?.path ?? "/auth/reset_password",
    meta: reset_passwordnU2aMAlGaZMeta || {},
    alias: reset_passwordnU2aMAlGaZMeta?.alias || [],
    redirect: reset_passwordnU2aMAlGaZMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/reset_password.vue").then(m => m.default || m)
  },
  {
    name: sign_fast_changeFj22Cif459Meta?.name ?? "auth-sign_fast_change",
    path: sign_fast_changeFj22Cif459Meta?.path ?? "/auth/sign_fast_change",
    meta: sign_fast_changeFj22Cif459Meta || {},
    alias: sign_fast_changeFj22Cif459Meta?.alias || [],
    redirect: sign_fast_changeFj22Cif459Meta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/sign_fast_change.vue").then(m => m.default || m)
  },
  {
    name: sign_innP6zoaZGLrMeta?.name ?? "auth-sign_in",
    path: sign_innP6zoaZGLrMeta?.path ?? "/auth/sign_in",
    meta: sign_innP6zoaZGLrMeta || {},
    alias: sign_innP6zoaZGLrMeta?.alias || [],
    redirect: sign_innP6zoaZGLrMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/sign_in.vue").then(m => m.default || m)
  },
  {
    name: sign_upTOz1ZxpDKIMeta?.name ?? "auth-sign_up",
    path: sign_upTOz1ZxpDKIMeta?.path ?? "/auth/sign_up",
    meta: sign_upTOz1ZxpDKIMeta || {},
    alias: sign_upTOz1ZxpDKIMeta?.alias || [],
    redirect: sign_upTOz1ZxpDKIMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/auth/sign_up.vue").then(m => m.default || m)
  },
  {
    name: email_editor8eAdQExB9lMeta?.name ?? "email_editor",
    path: email_editor8eAdQExB9lMeta?.path ?? "/email_editor",
    meta: email_editor8eAdQExB9lMeta || {},
    alias: email_editor8eAdQExB9lMeta?.alias || [],
    redirect: email_editor8eAdQExB9lMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/email_editor.vue").then(m => m.default || m)
  },
  {
    name: checkingX9umjB4MA3Meta?.name ?? "file-id-checking",
    path: checkingX9umjB4MA3Meta?.path ?? "/file/:id()/checking",
    meta: checkingX9umjB4MA3Meta || {},
    alias: checkingX9umjB4MA3Meta?.alias || [],
    redirect: checkingX9umjB4MA3Meta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/checking.vue").then(m => m.default || m)
  },
  {
    name: indexwdRDaTly8IMeta?.name ?? "file-id",
    path: indexwdRDaTly8IMeta?.path ?? "/file/:id()",
    meta: indexwdRDaTly8IMeta || {},
    alias: indexwdRDaTly8IMeta?.alias || [],
    redirect: indexwdRDaTly8IMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: messageF6Ujd4UcJaMeta?.name ?? "file-id-message",
    path: messageF6Ujd4UcJaMeta?.path ?? "/file/:id()/message",
    meta: messageF6Ujd4UcJaMeta || {},
    alias: messageF6Ujd4UcJaMeta?.alias || [],
    redirect: messageF6Ujd4UcJaMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/message.vue").then(m => m.default || m)
  },
  {
    name: previewOf9qYbaxzPMeta?.name ?? "file-id-preview",
    path: previewOf9qYbaxzPMeta?.path ?? "/file/:id()/preview",
    meta: previewOf9qYbaxzPMeta || {},
    alias: previewOf9qYbaxzPMeta?.alias || [],
    redirect: previewOf9qYbaxzPMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/preview.vue").then(m => m.default || m)
  },
  {
    name: shareMmGZ63Cl9iMeta?.name ?? "file-id-share",
    path: shareMmGZ63Cl9iMeta?.path ?? "/file/:id()/share",
    meta: shareMmGZ63Cl9iMeta || {},
    alias: shareMmGZ63Cl9iMeta?.alias || [],
    redirect: shareMmGZ63Cl9iMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/share.vue").then(m => m.default || m)
  },
  {
    name: signedolfizDCtI1Meta?.name ?? "file-id-signed",
    path: signedolfizDCtI1Meta?.path ?? "/file/:id()/signed",
    meta: signedolfizDCtI1Meta || {},
    alias: signedolfizDCtI1Meta?.alias || [],
    redirect: signedolfizDCtI1Meta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/signed.vue").then(m => m.default || m)
  },
  {
    name: template_previewA0tn30hkzcMeta?.name ?? "file-id-template_preview",
    path: template_previewA0tn30hkzcMeta?.path ?? "/file/:id()/template_preview",
    meta: template_previewA0tn30hkzcMeta || {},
    alias: template_previewA0tn30hkzcMeta?.alias || [],
    redirect: template_previewA0tn30hkzcMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/file/[id]/template_preview.vue").then(m => m.default || m)
  },
  {
    name: indexOxED3j6C0wMeta?.name ?? "index",
    path: indexOxED3j6C0wMeta?.path ?? "/",
    meta: indexOxED3j6C0wMeta || {},
    alias: indexOxED3j6C0wMeta?.alias || [],
    redirect: indexOxED3j6C0wMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/index.vue").then(m => m.default || m)
  },
  {
    name: v1_integrateh19X33iN2VMeta?.name ?? "integration-v1_integrate",
    path: v1_integrateh19X33iN2VMeta?.path ?? "/integration/v1_integrate",
    meta: v1_integrateh19X33iN2VMeta || {},
    alias: v1_integrateh19X33iN2VMeta?.alias || [],
    redirect: v1_integrateh19X33iN2VMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/integration/v1_integrate.vue").then(m => m.default || m)
  },
  {
    name: not_allowed9xjbxxm2jWMeta?.name ?? "not_allowed",
    path: not_allowed9xjbxxm2jWMeta?.path ?? "/not_allowed",
    meta: not_allowed9xjbxxm2jWMeta || {},
    alias: not_allowed9xjbxxm2jWMeta?.alias || [],
    redirect: not_allowed9xjbxxm2jWMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/not_allowed.vue").then(m => m.default || m)
  },
  {
    name: fast_authTrs2iQAXCSMeta?.name ?? "signer-id-fast_auth",
    path: fast_authTrs2iQAXCSMeta?.path ?? "/signer/:id()/fast_auth",
    meta: fast_authTrs2iQAXCSMeta || {},
    alias: fast_authTrs2iQAXCSMeta?.alias || [],
    redirect: fast_authTrs2iQAXCSMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/fast_auth.vue").then(m => m.default || m)
  },
  {
    name: indexgjTmm2wOYWMeta?.name ?? "signer-id",
    path: indexgjTmm2wOYWMeta?.path ?? "/signer/:id()",
    meta: indexgjTmm2wOYWMeta || {},
    alias: indexgjTmm2wOYWMeta?.alias || [],
    redirect: indexgjTmm2wOYWMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: preview9EUfyrv7skMeta?.name ?? "signer-id-preview",
    path: preview9EUfyrv7skMeta?.path ?? "/signer/:id()/preview",
    meta: preview9EUfyrv7skMeta || {},
    alias: preview9EUfyrv7skMeta?.alias || [],
    redirect: preview9EUfyrv7skMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/preview.vue").then(m => m.default || m)
  },
  {
    name: sentToJua5bw3yMeta?.name ?? "signer-id-sent",
    path: sentToJua5bw3yMeta?.path ?? "/signer/:id()/sent",
    meta: sentToJua5bw3yMeta || {},
    alias: sentToJua5bw3yMeta?.alias || [],
    redirect: sentToJua5bw3yMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/sent.vue").then(m => m.default || m)
  },
  {
    name: signingUAd69ezHUNMeta?.name ?? "signer-id-signing",
    path: signingUAd69ezHUNMeta?.path ?? "/signer/:id()/signing",
    meta: signingUAd69ezHUNMeta || {},
    alias: signingUAd69ezHUNMeta?.alias || [],
    redirect: signingUAd69ezHUNMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/signing.vue").then(m => m.default || m)
  },
  {
    name: summarygnz5AFJXTeMeta?.name ?? "signer-id-summary",
    path: summarygnz5AFJXTeMeta?.path ?? "/signer/:id()/summary",
    meta: summarygnz5AFJXTeMeta || {},
    alias: summarygnz5AFJXTeMeta?.alias || [],
    redirect: summarygnz5AFJXTeMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/summary.vue").then(m => m.default || m)
  },
  {
    name: validationL6fZin631YMeta?.name ?? "signer-id-validation",
    path: validationL6fZin631YMeta?.path ?? "/signer/:id()/validation",
    meta: validationL6fZin631YMeta || {},
    alias: validationL6fZin631YMeta?.alias || [],
    redirect: validationL6fZin631YMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/validation.vue").then(m => m.default || m)
  },
  {
    name: verificationTiC41YAVEDMeta?.name ?? "signer-id-verification",
    path: verificationTiC41YAVEDMeta?.path ?? "/signer/:id()/verification",
    meta: verificationTiC41YAVEDMeta || {},
    alias: verificationTiC41YAVEDMeta?.alias || [],
    redirect: verificationTiC41YAVEDMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/[id]/verification.vue").then(m => m.default || m)
  },
  {
    name: create_accountWVr1YZ3vtPMeta?.name ?? "signer-create_account",
    path: create_accountWVr1YZ3vtPMeta?.path ?? "/signer/create_account",
    meta: create_accountWVr1YZ3vtPMeta || {},
    alias: create_accountWVr1YZ3vtPMeta?.alias || [],
    redirect: create_accountWVr1YZ3vtPMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/create_account.vue").then(m => m.default || m)
  },
  {
    name: create_passwordkiDLPlSAUkMeta?.name ?? "signer-create_password",
    path: create_passwordkiDLPlSAUkMeta?.path ?? "/signer/create_password",
    meta: create_passwordkiDLPlSAUkMeta || {},
    alias: create_passwordkiDLPlSAUkMeta?.alias || [],
    redirect: create_passwordkiDLPlSAUkMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signer/create_password.vue").then(m => m.default || m)
  },
  {
    name: indexauTgDaHIzCMeta?.name ?? "signy-allDocuments",
    path: indexauTgDaHIzCMeta?.path ?? "/signy/allDocuments",
    meta: indexauTgDaHIzCMeta || {},
    alias: indexauTgDaHIzCMeta?.alias || [],
    redirect: indexauTgDaHIzCMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/allDocuments/index.vue").then(m => m.default || m)
  },
  {
    name: indexsCF7QsBgLiMeta?.name ?? "signy-auth",
    path: indexsCF7QsBgLiMeta?.path ?? "/signy/auth",
    meta: indexsCF7QsBgLiMeta || {},
    alias: indexsCF7QsBgLiMeta?.alias || [],
    redirect: indexsCF7QsBgLiMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/auth/index.vue").then(m => m.default || m)
  },
  {
    name: successN8GbkFJt8dMeta?.name ?? "signy-auth-success",
    path: successN8GbkFJt8dMeta?.path ?? "/signy/auth/success",
    meta: successN8GbkFJt8dMeta || {},
    alias: successN8GbkFJt8dMeta?.alias || [],
    redirect: successN8GbkFJt8dMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/auth/success.vue").then(m => m.default || m)
  },
  {
    name: contactsr0Ap9V5xJWMeta?.name ?? "signy-contacts",
    path: contactsr0Ap9V5xJWMeta?.path ?? "/signy/contacts",
    meta: contactsr0Ap9V5xJWMeta || {},
    alias: contactsr0Ap9V5xJWMeta?.alias || [],
    redirect: contactsr0Ap9V5xJWMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/contacts.vue").then(m => m.default || m)
  },
  {
    name: dashboardexPPnQ6Cj4Meta?.name ?? "signy-dashboard",
    path: dashboardexPPnQ6Cj4Meta?.path ?? "/signy/dashboard",
    meta: dashboardexPPnQ6Cj4Meta || {},
    alias: dashboardexPPnQ6Cj4Meta?.alias || [],
    redirect: dashboardexPPnQ6Cj4Meta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/dashboard.vue").then(m => m.default || m)
  },
  {
    name: erroryZN0bX7zpYMeta?.name ?? "signy-error",
    path: erroryZN0bX7zpYMeta?.path ?? "/signy/error",
    meta: erroryZN0bX7zpYMeta || {},
    alias: erroryZN0bX7zpYMeta?.alias || [],
    redirect: erroryZN0bX7zpYMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/error.vue").then(m => m.default || m)
  },
  {
    name: all_documentsnGm4O8PUKjMeta?.name ?? "signy-explorer-all_documents",
    path: all_documentsnGm4O8PUKjMeta?.path ?? "/signy/explorer/all_documents",
    meta: all_documentsnGm4O8PUKjMeta || {},
    alias: all_documentsnGm4O8PUKjMeta?.alias || [],
    redirect: all_documentsnGm4O8PUKjMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/all_documents.vue").then(m => m.default || m)
  },
  {
    name: indexUeVipOXSemMeta?.name ?? "signy-explorer-contacts",
    path: indexUeVipOXSemMeta?.path ?? "/signy/explorer/contacts",
    meta: indexUeVipOXSemMeta || {},
    alias: indexUeVipOXSemMeta?.alias || [],
    redirect: indexUeVipOXSemMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: formsprr1bSDPDWMeta?.name ?? "signy-explorer-forms",
    path: formsprr1bSDPDWMeta?.path ?? "/signy/explorer/forms",
    meta: formsprr1bSDPDWMeta || {},
    alias: formsprr1bSDPDWMeta?.alias || [],
    redirect: formsprr1bSDPDWMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/forms.vue").then(m => m.default || m)
  },
  {
    name: indexlWMBo13UkFMeta?.name ?? "signy-explorer-groups",
    path: indexlWMBo13UkFMeta?.path ?? "/signy/explorer/groups",
    meta: indexlWMBo13UkFMeta || {},
    alias: indexlWMBo13UkFMeta?.alias || [],
    redirect: indexlWMBo13UkFMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/groups/index.vue").then(m => m.default || m)
  },
  {
    name: index4DiuJzLswdMeta?.name ?? "signy-explorer",
    path: index4DiuJzLswdMeta?.path ?? "/signy/explorer",
    meta: index4DiuJzLswdMeta || {},
    alias: index4DiuJzLswdMeta?.alias || [],
    redirect: index4DiuJzLswdMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/index.vue").then(m => m.default || m)
  },
  {
    name: old_45contactszj4o0rIe05Meta?.name ?? "signy-explorer-old-contacts",
    path: old_45contactszj4o0rIe05Meta?.path ?? "/signy/explorer/old-contacts",
    meta: old_45contactszj4o0rIe05Meta || {},
    alias: old_45contactszj4o0rIe05Meta?.alias || [],
    redirect: old_45contactszj4o0rIe05Meta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/old-contacts.vue").then(m => m.default || m)
  },
  {
    name: templatestZWmLpjUybMeta?.name ?? "signy-explorer-templates",
    path: templatestZWmLpjUybMeta?.path ?? "/signy/explorer/templates",
    meta: templatestZWmLpjUybMeta || {},
    alias: templatestZWmLpjUybMeta?.alias || [],
    redirect: templatestZWmLpjUybMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/templates.vue").then(m => m.default || m)
  },
  {
    name: trashGd2V0m6mlCMeta?.name ?? "signy-explorer-trash",
    path: trashGd2V0m6mlCMeta?.path ?? "/signy/explorer/trash",
    meta: trashGd2V0m6mlCMeta || {},
    alias: trashGd2V0m6mlCMeta?.alias || [],
    redirect: trashGd2V0m6mlCMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/explorer/trash.vue").then(m => m.default || m)
  },
  {
    name: indexzAZ7zRk1sPMeta?.name ?? "signy",
    path: indexzAZ7zRk1sPMeta?.path ?? "/signy",
    meta: indexzAZ7zRk1sPMeta || {},
    alias: indexzAZ7zRk1sPMeta?.alias || [],
    redirect: indexzAZ7zRk1sPMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/index.vue").then(m => m.default || m)
  },
  {
    path: my_account0zRmcyxw3LMeta?.path ?? "/signy/my_account",
    children: [
  {
    name: documentspGfmQtJLx0Meta?.name ?? "signy-my_account-documents",
    path: documentspGfmQtJLx0Meta?.path ?? "documents",
    meta: documentspGfmQtJLx0Meta || {},
    alias: documentspGfmQtJLx0Meta?.alias || [],
    redirect: documentspGfmQtJLx0Meta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account/documents.vue").then(m => m.default || m)
  },
  {
    name: filesGA6EZQQCSyMeta?.name ?? "signy-my_account-files",
    path: filesGA6EZQQCSyMeta?.path ?? "files",
    meta: filesGA6EZQQCSyMeta || {},
    alias: filesGA6EZQQCSyMeta?.alias || [],
    redirect: filesGA6EZQQCSyMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account/files.vue").then(m => m.default || m)
  },
  {
    name: indexefQWCDOTkdMeta?.name ?? "signy-my_account",
    path: indexefQWCDOTkdMeta?.path ?? "",
    meta: indexefQWCDOTkdMeta || {},
    alias: indexefQWCDOTkdMeta?.alias || [],
    redirect: indexefQWCDOTkdMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account/index.vue").then(m => m.default || m)
  },
  {
    name: organizationsbdLgkJ864KMeta?.name ?? "signy-my_account-organizations",
    path: organizationsbdLgkJ864KMeta?.path ?? "organizations",
    meta: organizationsbdLgkJ864KMeta || {},
    alias: organizationsbdLgkJ864KMeta?.alias || [],
    redirect: organizationsbdLgkJ864KMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account/organizations.vue").then(m => m.default || m)
  },
  {
    name: profileETK9f8iXgmMeta?.name ?? "signy-my_account-profile",
    path: profileETK9f8iXgmMeta?.path ?? "profile",
    meta: profileETK9f8iXgmMeta || {},
    alias: profileETK9f8iXgmMeta?.alias || [],
    redirect: profileETK9f8iXgmMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account/profile.vue").then(m => m.default || m)
  },
  {
    name: indexurT2e6FZhwMeta?.name ?? "signy-my_account-settings",
    path: indexurT2e6FZhwMeta?.path ?? "settings",
    meta: indexurT2e6FZhwMeta || {},
    alias: indexurT2e6FZhwMeta?.alias || [],
    redirect: indexurT2e6FZhwMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account/settings/index.vue").then(m => m.default || m)
  }
],
    name: my_account0zRmcyxw3LMeta?.name ?? undefined,
    meta: my_account0zRmcyxw3LMeta || {},
    alias: my_account0zRmcyxw3LMeta?.alias || [],
    redirect: my_account0zRmcyxw3LMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/my_account.vue").then(m => m.default || m)
  },
  {
    name: pdf_creatorJ2YWyBhtFoMeta?.name ?? "signy-pdf_creator",
    path: pdf_creatorJ2YWyBhtFoMeta?.path ?? "/signy/pdf_creator",
    meta: pdf_creatorJ2YWyBhtFoMeta || {},
    alias: pdf_creatorJ2YWyBhtFoMeta?.alias || [],
    redirect: pdf_creatorJ2YWyBhtFoMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/pdf_creator.vue").then(m => m.default || m)
  },
  {
    name: pdf_viewerHaDdyXLPapMeta?.name ?? "signy-pdf_viewer",
    path: pdf_viewerHaDdyXLPapMeta?.path ?? "/signy/pdf_viewer",
    meta: pdf_viewerHaDdyXLPapMeta || {},
    alias: pdf_viewerHaDdyXLPapMeta?.alias || [],
    redirect: pdf_viewerHaDdyXLPapMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/pdf_viewer.vue").then(m => m.default || m)
  },
  {
    name: plansBUNS91VT0NMeta?.name ?? "signy-plans",
    path: plansBUNS91VT0NMeta?.path ?? "/signy/plans",
    meta: plansBUNS91VT0NMeta || {},
    alias: plansBUNS91VT0NMeta?.alias || [],
    redirect: plansBUNS91VT0NMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/plans.vue").then(m => m.default || m)
  },
  {
    name: upload_documentrDzbVvftgiMeta?.name ?? "signy-upload_document",
    path: upload_documentrDzbVvftgiMeta?.path ?? "/signy/upload_document",
    meta: upload_documentrDzbVvftgiMeta || {},
    alias: upload_documentrDzbVvftgiMeta?.alias || [],
    redirect: upload_documentrDzbVvftgiMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/signy/upload_document.vue").then(m => m.default || m)
  },
  {
    name: v1_integrationGDtFMpTYNyMeta?.name ?? "v1_integration",
    path: v1_integrationGDtFMpTYNyMeta?.path ?? "/v1_integration",
    meta: v1_integrationGDtFMpTYNyMeta || {},
    alias: v1_integrationGDtFMpTYNyMeta?.alias || [],
    redirect: v1_integrationGDtFMpTYNyMeta?.redirect || undefined,
    component: () => import("/var/www/app-front/apps/pages/v1_integration.vue").then(m => m.default || m)
  }
]